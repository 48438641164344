<template>
    <p><strong>{{ lead.contact_person }}</strong></p>
    <p>{{ lead.job }}</p>
    <p>{{ lead.company }} 
    <a v-if="lead.company && lead.loc" :href="encodeURI('https://www.google.com/maps/search/?api=1&query='+lead.company+' near '+lead.loc)" target="blank">
        &#127757;
    </a>
    </p> 
    <p class="smaller">{{ lead.loc}}</p>
    <p v-if="lead.snippet" class="lil_snip">{{ lead.snippet }}</p>
    <div><strong>Found as: </strong>{{lead.from_search}}</div>

    <div v-if="lead.linkedin" style="white-space: nowrap; overflow: hidden;">
        <strong>LinkedIn:</strong> <a :href="lead.linkedin" target="_blank">{{lead.linkedin}}</a>
    </div>
    
    <!-- LLM Message -->
    <LeadLlmMessage v-if="project" :lead="lead" :project="project" />
    <br />
    <EmailSaas :lead="lead" :project="project" />


    <br />
    <div style="float:right">
        <LeadEditModal :lead="lead" />
    </div>


    <LeadEnrichButtons :item="lead" :action="'social'" />
    <LeadEnrichButtons :item="lead" :action="'web'" />
    <LeadEnrichButtons :item="lead" :action="'company'" />

    <br />


    
</template>

<script>
    import axios from 'axios'
    import {toast} from 'bulma-toast'

    import EmailSaas from '@/components/services/EmailSaas'
    import LeadEditModal from '@/components/services/LeadEditModal'
    import LightSearch from '@/components/services/LightSearch'
    import LeadEnrichButtons from '@/components/services/LeadEnrichButtons'
    import LeadLlmMessage from '@/components/services/LeadLlmMessage'
    export default {
        name: 'LeadInList',
        components: {EmailSaas, LeadEditModal, LightSearch, LeadEnrichButtons, LeadLlmMessage},
        props: {
            lead: '',
            project: {
                msg_subject: '',
                msg_body: '',
                id: 0

            },
        },
        data(){return{
            isHidden: true,
            // llm_message: '',
            // llm_message_html: '',
            warnEmptyMsg: false,
            llm_btn: {
                name: 'AI Aided Email',
                price: 8,         
                icon: '&#127917;',

            },
            errors: [],
            err_msg: ''
        }},
        
    }
</script>
<style>
    .ld_itm {
        padding: 16px;
        margin: 12px 0;
        background-color: #f0f0f0;
        border-bottom: 1px solid #fff;
    }
    .ld_info {
        white-space: pre-wrap;
    }
    .clr{
        display: inline;
    }
    .lil_details {
        cursor:  pointer;
        color: #009;
    }
    .lil_snip{
        font-size: smaller;
        border-top: 1px dashed #999;
        border-bottom: 1px dashed #999;
    }
</style>