<template>
    <!--nav class="navbar is-dark"-->
    <nav class="navbar box1">
        <div class="navbar-brand">
            <router-link to="/" class="navbar-item">
                <img :src="require('/public/b2bsearch-logo.png')" alt="B2BSearch logo">
            </router-link>
        </div>
        <div class="navbar-menu is-active">
            <router-link to="/team/payment" class="navbar-item" v-if="$store.state.isAuthenticated">
                <div class="blnc">
                    <span class="blnc1">Balance: <span style="color: #666">{{$store.state.teamCredits}}</span> </span> 
                    <span style="font-weight: 500;"> credits</span> 
                    <span style="white-space: nowrap; font-weight: 500;"> (1 Euro = 100 credits)</span>
                </div>               
            </router-link>
            <div class="navbar-end">
                <div class="buttons navmenu">
                    <template v-if="$store.state.isAuthenticated">

                        <router-link @click="goHome()" to="/" class="navbar-item">Search</router-link>
                        <!-- router-link to="/team-searches" class="navbar-item">Searches</router-link -->
                        <router-link to="/project" class="navbar-item">Projects</router-link>
                        <router-link to="/dashboard/leads" class="navbar-item">Leads</router-link>
                        <!-- router-link to="/dashboard/team" class="navbar-item">Team</router-link -->
                    </template>

                    <router-link to="/pub/how-to" class="navbar-item">FAQ</router-link>
                </div>
                <div class="navbar-item">
                    <div class="buttons">
                        <template v-if="!$store.state.isAuthenticated">
                            <router-link to="/sign-up" class="button is-bbred"><strong>Sign up</strong></router-link>
                            <router-link to="/log-in" class="button is-bblight">Log in</router-link>
                        </template>

                        <template v-else>
                            <router-link to="/dashboard/my-account" class="button">
                                Me<span class="myaccsep">&</span>Team 
                            </router-link>
                            
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
    import axios from 'axios'
    export default {
        name: 'Navbar',
        
        mounted() {
            if(this.$store.state.isAuthenticated) {
                this.getTeamCredits()
            }
        },
        methods: {
            goHome() {                
                let url1=window.location.href.split('/')
                if(url1[3] === ""){ //home page
                    window.location.reload()
                    //this.$router.push('/')
                }
            },
            async getTeamCredits() {
                this.$store.commit('setIsLoading', true)

                await axios
                    .get('/lms/teams/credits/')
                    .then(response => {
                        //console.log('NavBar getTeamCredits', response)
                        this.$store.state.teamCredits = response.data.team_credits
                    })
                    .catch(error => {
                        console.log(error)
                    })
                this.$store.commit('setIsLoading', false)
            }
        }
    }
</script>
<style>

    .box1 {
        box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);
        color: #4a4a4a;
    }
    .navbar-item {
        /*display: inline-block !important;*/
        font-weight:  700 !important;
        cursor: pointer;
        justify-content: center;
        /*padding: 5rem 0.75rem;*/
        padding: 56px;
        text-align: center;
        align-items: center;
    }
    .navmenu {
        display: inline-block;
        margin-top: 20px;
    }
    .navbar-item img {
        max-height: 3rem !important;
    }

    .navbar-item:hover {
        cursor: pointer;
    }
    .navbar-end {
        padding-top: 6px;
    }
    .myaccsep {
        color: #aaa;
    }
    .blnc {
        color: #bbb;
        /*display: inline-block;*/
    }
    .blnc1 {
        font-size: 1.5em !important;
    }




</style>
