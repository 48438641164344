<template>
	<!--div class="buttons has-addons" -->
		<button :class="{'button':true, 'comp_btn':true, 'is-loading':btnLoading}" @click="llmMessage">
			{{btn.name}} <span class="credits1">{{btn.price}}</span>
		</button>&nbsp;
	  	<button class="button btnln" @click="langChangeInProgr=true">{{project.llm_lang}}</button>
  	
	<!--  /div -->
		<span v-if="langChangeInProgr" class="lng_bl">
	  		<button class="button btnln" v-for="l in llm_langs" @click="langSetThis(l)"> {{l}} </button>
	  	</span>	

	<div v-if="llm_message">
		<textarea disabled rows="20" cols="40" v-model="llm_message"></textarea>

		<button class="button" @click="copyMessage">Copy message to clipboard</button>
		<br /><br />
		<p>🡇 Click the email address below to open the AI aided message in your default mail client.</p>
		
	</div>

	<div class="modal" :class="{'is-active': warnEmptyMsg}">
	    <div class="modal-background"></div>
	    <div class="modal-card">
	    	<header class="modal-card-head">
	            <div><b>Message Required</b></div>
	        </header>
	        <section class="modal-card-body">            

                <p>Fill in the Message Template at the top of this page and press the yellow button "AI Aided Email" again.</p>
                
	        </section>
	        <footer class="modal-card-foot">
	            <button class="button" @click="cancelModal">Close</button>
	        </footer>
	    </div>
	</div>
</template>

<script>
    import axios from 'axios'
    import {toast} from 'bulma-toast'

    export default {
    	name: 'LeadLlmMessage',
    	props: {
    		lead: {},
    		project: {},
    		eml_addr: '',
    	},
    	data(){return{
    		llm_message: '',
    		llm_message_html: '',
    		llm_langs: ['EN', 'DE', 'ES', 'FR', 'IT', 'NL', 'PL', 'RU', 'SW', 'CH'],
    		langChangeInProgr: false,
    		btnLoading: false,
    		warnEmptyMsg: false,
    		btn: {
		    	name: 'AI Aided Message',
		    	price: 8,		  
		    	icon: '&#127917;',

	    	},
	    	errors: [],
	    	err_msg: ''
    	}},
    	
    	
    	methods:{
    	    async llmMessage() {
		    	// Message personalization with LLM 
		    	//console.log("lead", this.lead)
		    	//console.log("eml_addr", this.eml_addr)
		    	//console.log("project", this.project)
		    	if (this.project.msg_body == '') {
		    		this.warnEmptyMsg = true
		    	} else {
		    		this.$store.commit('setIsLoading', true)
		    		this.btnLoading = true
			    	await axios
			            .post('/lms/llm-message/', {lead:this.lead.id, project:this.project})
			            .then(response => {
			            	const llm_message = response.data.result
			            	// console.log('llm_message', llm_message)
			            	this.$store.state.teamCredits = response.data.team_credits

			            	if(!response.data.credit_status) {
								this.$store.state.creditsOut = true
			            	} else {
			            		this.llm_message = response.data.llm_message
			            		if (this.llm_message === 'Err') {
			            			toast({
				                        message: 'LLM is overloaded. Please, try again after 3 seconds',
				                        type: 'is-danger',
				                        dismissible: true,
				                        pauseOnHover: true,
				                        duration: 3000,
				                        position: 'center',
			                        })

			            		} else {
									this.lead.llm_subject = response.data.llm_subject 
									this.lead.llm_message = this.llm_message
				            	}
			            	}
			            	
			            })
			            .catch(error => {
			            	console.log('Err!', error)
                            if (error.response) {
                            	this.err_msg = `Something went wrong (err ${error.response.status}). Please, try again`
                                this.errors.push(this.err_msg)
                                /*
                                for (const property in error.response.data) {  
                                    this.errors.push(`${property}: ${error.response.data[property]}`)
                                }
                                */
                            } else if (error.message) {
                            	this.err_msg = 'Something went wrong. Please try again!'
                                this.errors.push(this.err_msg)
                            }
							toast({
		                        message: this.err_msg,
		                        type: 'is-danger',
		                        dismissible: true,
		                        pauseOnHover: true,
		                        duration: 3000,
		                        position: 'center',
	                        })
	                    })
	            	this.btnLoading = false
		        	this.$store.commit('setIsLoading', false)
		    	}
		    },
		    langSetThis(l) {
		    	this.project.llm_lang = l
		    	this.langChangeInProgr=false
			},
		    cancelModal() {
                this.warnEmptyMsg = false
            },
            copyMessage() {
            	navigator.clipboard.writeText(this.llm_message)
    			toast({
                    message: "Copied to clipboard",
                    type: 'is-success',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 2000,
                    position: 'center',
                })
            }
		}
	}
</script>
<style lang="scss">

	.comp_btn {
		background-color: #dd0;
		cursor: pointer;
		
		&:hover {
			background-color: #aa0;
		}
	}

	textarea {
		width:100%; 
		padding: 12px;
		font-size: 1em;
		background: #eea;
	}

	.btnln {
		cursor: pointer;
		box-sizing: border-box;
		border-radius:50% 50% 50% 50%;
		width: 3em;
		font-weight: 600;
		font-size: 12px;

		border: 2px solid;

		margin: 3px 4px;
		
		&:hover {
			background-color: #dd0;
			box-sizing: border-box;
			border: 3px solid;
		}
	}

	.lng_bl {
		display: inline-block;
		border-radius: 0.3em;
		background-color: #ddc;
	}

</style>
