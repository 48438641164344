<template>
    <div>
        <Navbar />

        <section class="section">
            <router-view/>
        </section>

        <div class="is-loading-bar has-text-centered" v-bind:class="{'is-loading': $store.state.isLoading }">
            <div class="lds-dual-ring"></div>
        </div>

        <div class="modal" v-bind:class="{'is-active': $store.state.creditsOut}"  @click="creditsOutClose">
            <div class="modal-background"></div>
            <div class="modal-card">
                <section class="modal-card-body"> 
                    <button class="button" @click="close" style="float:right">Close</button>
                    <p><b>Not enough credits to perform this operation.</b></p>
                    <br />
                    <p>Please <router-link to="/team/payment">buy some credits</router-link>.</p>
                    <br />
                    <p>You may view your activity <router-link to="/team/actions">here</router-link>.</p>
                    
                </section>
            </div>
        </div>

        <Footer />
    </div>
</template>

<script>
    import axios from 'axios'
    import Navbar from '@/components/layout/Navbar'
    import Footer from '@/components/layout/Footer'
    /* import './assets/fbq.js' */

    export default {
        name: 'App',
        components: {
            Navbar,
            Footer,
        },
        beforeCreate() {
            this.$store.commit('initializeStore')

            if (this.$store.state.token) {
                axios.defaults.headers.common['Authorization'] = "Token " + this.$store.state.token
            } else {
                axios.defaults.headers.common['Authorization'] = ""
            }

            /*
            if (!this.$store.state.team.id) {
                this.$router.push('/dashboard/add-team')
            }
            */
        },
        methods: {
            creditsOutClose() {
                this.$store.state.creditsOut = false
            },
            
        }
    }
</script>

<style lang="scss">
@import '../node_modules/bulma';

.is-bbred {
    background-color: #FD754F;
    color: #fff;
    font-weight: 600;
}

.is-bbred:hover {
    background-color: #E55426;
    color: #fff;
    font-weight: 600;
}

.is-bblight {
    background-color: #fff;
    border-color: #333;
    color: #333;
    font-weight: 600;
}

.is-bblight:hover {
    background-color: #fff;
    border-color: #FD754F;
    color: #FD754F;
    font-weight: 600;
}

.credits1 {
    //display: block;
    //margin-top: -28px;
    padding: 0 6px;
    font-size: small;
    /* background-color: #f66; */
    border-radius: 12px;
    font-weight: 800;

    &:after {
    content: 'credits';
    font-weight: 400;
    font-size: smaller;
    padding-left: 2px;
    }

    &:before{
        content: " | ";
        font-weight: 200;
        color: #999;
    }
}


.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #ccc;
    border-color: #ccc transparent #ccc transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.is-loading-bar {
    height: 0;
    overflow: hidden;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;

    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    
    &.is-loading {
        height: 80px;
    }
}


</style>
